import * as React from 'react'

import { SliceComponentProps } from '@prismicio/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { ButtonLink } from '../../common/components/button-link'
import { highlightString, useMediaQuery } from '../../util'

import { ProductShowcaseSlice } from './types'

export const ProductShowcase = ({ slice }: SliceComponentProps<ProductShowcaseSlice>) => {
  const isDesktop = useMediaQuery('(min-width: 1024px) and (max-width: 3000px)')
  const isTablet = useMediaQuery('(min-width: 464px) and (max-width: 1024px)')
  const isMobile = useMediaQuery('(min-width: 0px) and (max-width: 464px)')
  const products = slice.items.filter(product => !!product.image?.gatsbyImageData)
  /* istanbul ignore next */
  const showDots =
    (isDesktop && products.length > 3) ||
    (isTablet && products.length > 2) ||
    (isMobile && products.length > 1)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: products.length > 3 ? 60 : 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: products.length > 2 ? 50 : 0,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: products.length > 1 ? 90 : 0,
    },
  }

  return (
    <div className="py-8 lg:max-w-7xl lg:mx-auto">
      {slice.primary.heading?.text && (
        <div
          className="text-4xl px-4 lg:text-center lg:text-6xl"
          dangerouslySetInnerHTML={{
            __html: highlightString(slice.primary.heading.text, slice.primary.highlightColor || ''),
          }}
        />
      )}
      <Carousel
        responsive={responsive}
        removeArrowOnDeviceType={['mobile', 'tablet', 'desktop']}
        containerClass="h-[580px]"
        itemClass="px-4 lg:px-2"
        infinite={false}
        keyBoardControl
        partialVisible
        showDots={showDots}
      >
        {products.map((product, index) => {
          const image = getImage(product.image)

          // NOTE: Images will always be truthy because they are filtered in the products array.
          /* istanbul ignore if */
          if (!image) {
            return <React.Fragment key={index}></React.Fragment>
          }

          return (
            <div
              className="rounded-lg bg-white border border-gray-200 h-[500px] flex flex-col p-5 items-center justify-center text-lg"
              key={index}
            >
              <a
                href={product.ctaLink?.text || ''}
                target={product.openLinkInNewTab ? '_blank' : '_self'}
              >
                <GatsbyImage
                  alt={product.image.alt || product.title?.text || 'Product Image'}
                  draggable="false"
                  image={image}
                  style={{ cursor: product.ctaLink?.text ? 'pointer' : 'auto' }}
                  title={product.title?.text}
                />
              </a>
              {product.title?.text && (
                <span className="text-center mb-4 text-2xl">{product.title.text}</span>
              )}
              <div
                className="grid items-center w-full"
                style={{ gridTemplateColumns: product.price?.text ? '2fr' : '1fr' }}
              >
                {product.price?.text && (
                  <span className="block text-xl">${product.price.text}</span>
                )}
                {!!product.ctaLink?.text && !!product.ctaText?.text && (
                  <ButtonLink
                    backgroundColor={slice.primary.highlightColor || '#000000'}
                    to={product.ctaLink.text}
                    small
                    style={{
                      justifySelf: product.price?.text ? 'end' : 'center',
                    }}
                    target={product.openLinkInNewTab ? '_blank' : '_self'}
                    textColor="#FFFFFF"
                    title={product.title?.text}
                  >
                    {product.ctaText.text}
                  </ButtonLink>
                )}
              </div>
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}
