import * as React from 'react'

import { SliceComponentProps } from '@prismicio/react'

import { BrandProfileSlice } from './types'

export const BrandProfile = ({ slice }: SliceComponentProps<BrandProfileSlice>) => (
  <div
    className="py-16 text-center"
    style={{ backgroundColor: slice.primary.backgroundColor || '#ffffff' }}
  >
    {slice.primary.title?.text && (
      <span className="block font-medium mb-2 text-2xl lg:text-5xl">
        {slice.primary.title.text}
      </span>
    )}
    <div className="lg:flex lg:items-center lg:justify-center lg:divide-x-2">
      {slice.items?.map(item => (
        <span
          className="block font-semibold lg:tracking-widest lg:px-10 lg:text-2xl border-black"
          key={item.attribute?.text}
        >
          {item.attribute?.text}
        </span>
      ))}
    </div>
  </div>
)
