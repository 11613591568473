import * as React from 'react'

import { PrismicRichText, SliceComponentProps } from '@prismicio/react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import { highlightString } from '../../util'

import { VideoShowcaseSlice } from './types'

export const VideoShowcase = ({ slice }: SliceComponentProps<VideoShowcaseSlice>) => {
  const url = slice.primary.video.embedUrl
  if (url.indexOf('v=') === -1) {
    return <></>
  }
  const videoId = url.substring(url.indexOf('v=') + 2, url.length)

  return (
    <div
      style={{
        background:
          'radial-gradient(40.78% 40.78% at 56.61% -7.33%, rgba(224, 215, 211, 0.15) 0%, rgba(224, 215, 211, 0) 100%), rgb(35, 31, 32)',
      }}
    >
      <div className="grid lg:grid-cols-2 items-center mx-auto lg:max-w-7xl">
        <LiteYouTubeEmbed
          id={videoId}
          title="Embedded YouTube Video"
          poster="maxresdefault"
          noCookie
        />
        <div className="p-11 text-tan-100 text-center lg:text-left">
          {slice.primary.title?.text && (
            <div
              className="text-3xl lg:text-5xl font-medium mb-4 lg:mb-8"
              dangerouslySetInnerHTML={{
                __html: `<span>${highlightString(
                  slice.primary.title.text,
                  slice.primary.highlightColor || ''
                )}</span>`,
              }}
            />
          )}
          <div className="text-tan-200 font-secondary text-xl">
            <PrismicRichText fallback={<></>} field={slice.primary.subtitle.richText} />
          </div>
        </div>
      </div>
    </div>
  )
}
