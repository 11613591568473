import { useEffect, useState } from 'react'

export const highlightString = (str: string, colorHex: string) => {
  if (typeof str !== 'string') {
    return ''
  }

  let counter = 0
  let updatedString = str
  let targetIndex = updatedString.indexOf('^^')

  while (targetIndex !== -1) {
    const newStr = counter % 2 === 0 ? `<span style="color:${colorHex};">` : '</span>'
    const firstSub = updatedString.substring(0, targetIndex)
    const secondSub = updatedString.substring(targetIndex).replace('^^', newStr)

    updatedString = firstSub + secondSub
    targetIndex = updatedString.indexOf('^^')
    counter++
  }

  return updatedString
}

export const useMediaQuery = (query: string): boolean => {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches
    }
    return false
  }

  const [matches, setMatches] = useState<boolean>(getMatches(query))

  const handleChange = () => {
    setMatches(getMatches(query))
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query)

    // Triggered at the first client-side load and if query changes
    handleChange()

    // Listen matchMedia
    matchMedia.addEventListener('change', handleChange)

    return () => {
      matchMedia.removeEventListener('change', handleChange)
    }
  }, [query])

  return matches
}
