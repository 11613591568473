import * as React from 'react'

import { PrismicRichText, SliceComponentProps } from '@prismicio/react'

import { ButtonLink } from '../../common/components/button-link'

import { TextWithButtonSlice } from './types'

export const TextWithButton = ({ slice }: SliceComponentProps<TextWithButtonSlice>) => (
  <div style={{ backgroundColor: slice.primary.backgroundColor || '#ffffff' }}>
    <div className="grid sm:grid-cols-2 p-4 text-center sm:text-left lg:justify-items-center lg:px-0 lg:py-5 items-center mx-auto lg:max-w-7xl">
      <div>
        {slice.primary.title?.text && (
          <span className="text-3xl lg:text-6xl">{slice.primary.title.text}</span>
        )}
      </div>
      <div className="lg:text-lg font-secondary text-xl lg:pl-10">
        <PrismicRichText fallback={<></>} field={slice.primary.subtitle?.richText} />
        {slice.primary.buttonLink?.text && (
          <ButtonLink
            backgroundColor={slice.primary.buttonBackgroundColor || undefined}
            className="mt-4 text-base font-primary"
            textColor={slice.primary.buttonTextColor || undefined}
            target={slice.primary.openLinkInNewTab ? '_blank' : '_self'}
            title={slice.primary.buttonText?.text}
            to={slice.primary.buttonLink.text}
          >
            {slice.primary.buttonText?.text || ''}
          </ButtonLink>
        )}
      </div>
    </div>
  </div>
)
