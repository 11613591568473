import * as React from 'react'

import { Link } from 'gatsby'

interface Props {
  /** Background color represented in hexadecimal */
  backgroundColor?: string
  children: React.ReactNode
  className?: string
  small?: boolean
  style?: React.CSSProperties
  target?: string
  /** Text color represented in hexadecimal */
  textColor?: string
  title?: string
  /** The destination of the link */
  to: string
}

export const ButtonLink = ({
  backgroundColor = '#000000',
  children,
  className = '',
  small = false,
  style,
  target,
  textColor = '#ffffff',
  title,
  to,
}: Props) => {
  const [isHover, setIsHover] = React.useState(false)
  const isInternalLink = to.startsWith('/')

  const props = {
    className: `inline-block lg:w-auto py-4 px-8 text-center rounded-lg text-lg ${
      small ? 'h-10 lg:min-w-28 inline-flex justify-center items-center' : 'w-52 lg:min-w-52'
    } ${className}`,
    onMouseEnter: () => setIsHover(true),
    onMouseLeave: () => setIsHover(false),
    style: {
      backgroundColor: isHover ? 'white' : backgroundColor,
      borderColor: isHover ? 'black' : backgroundColor,
      borderWidth: '1px',
      color: isHover ? 'black' : textColor,
      transition: 'all 0.2s',
      ...style,
    },
    target,
    title,
  }

  return isInternalLink ? (
    <Link to={to} {...props}>
      {children}
    </Link>
  ) : (
    <a href={to} {...props}>
      {children}
    </a>
  )
}
