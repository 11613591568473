import * as React from 'react'

import { PrismicRichText, SliceComponentProps } from '@prismicio/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { ButtonLink } from '../../common/components/button-link'
import { highlightString } from '../../util'

import { ImageAndTextSlice } from './types'

export const ImageAndText = ({ slice }: SliceComponentProps<ImageAndTextSlice>) => {
  const image = getImage(slice.primary.image)

  return (
    <div
      style={{
        backgroundColor: slice.primary.backgroundColor || '#ffffff',
        backgroundImage: slice.primary.backgroundImage?.url
          ? `url(${slice.primary.backgroundImage.url})`
          : undefined,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div
        className={`flex items-center mx-auto p-4 lg:max-w-7xl md:space-x-20 [&>*]:flex-1 ${
          slice.primary.textOnLeft
            ? 'flex-col-reverse md:flex-row-reverse'
            : 'flex-col-reverse md:flex-row'
        }`}
      >
        <div className="py-5 md:py-0 md:px-5">
          {slice.primary.headline?.text && (
            <div
              dangerouslySetInnerHTML={{
                __html: `<span class="block pb-5 text-center md:text-left text-5xl">${highlightString(
                  slice.primary.headline.text,
                  slice.primary.highlightColor || ''
                )}</span>`,
              }}
            />
          )}
          <div className="font-secondary">
            <PrismicRichText fallback={<></>} field={slice.primary.content?.richText} />
          </div>
          {slice.primary.ctaLink?.text && (
            <div className="text-center md:text-left">
              <ButtonLink
                backgroundColor={slice.primary.ctaBackgroundColor || undefined}
                className="mt-4"
                textColor={slice.primary.ctaTextColor || undefined}
                title={slice.primary.ctaText?.text}
                to={slice.primary.ctaLink.text}
              >
                {slice.primary.ctaText?.text}
              </ButtonLink>
            </div>
          )}
        </div>
        {image !== undefined && (
          <div className={`md:py-14${!slice.primary.textOnLeft ? '' : ' !ml-0'}`}>
            <GatsbyImage alt={slice.primary.image?.alt || 'Column Image'} image={image} />
          </div>
        )}
      </div>
    </div>
  )
}
