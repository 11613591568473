import * as React from 'react'

import { PrismicRichText, SliceComponentProps } from '@prismicio/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { ButtonLink } from '../../common/components/button-link'
import { highlightString } from '../../util'

import { ColumnedContentSlice } from './types'

export const ColumnedContent = ({ slice }: SliceComponentProps<ColumnedContentSlice>) => {
  const columns = slice.items.filter(col => !!col.image?.gatsbyImageData)

  return (
    <div
      style={{
        backgroundColor: slice.primary.backgroundColor || '#ffffff',
        backgroundImage: slice.primary.backgroundImage?.url
          ? `url(${slice.primary.backgroundImage.url})`
          : undefined,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: slice.primary.textColor || '#000000',
      }}
    >
      <div className="items-center text-center mx-auto py-10 p-4 lg:max-w-7xl">
        {slice.primary.headline?.text && (
          <div
            className="block pb-5 text-4xl lg:text-5xl"
            dangerouslySetInnerHTML={{
              __html: highlightString(
                slice.primary.headline.text,
                slice.primary.highlightColor || ''
              ),
            }}
          />
        )}
        <div className="font-secondary">
          {!!columns && (
            <div className="flex flex-col lg:flex-row lg:space-x-20">
              {columns.map((column, index) => {
                const image = getImage(column.image)

                return (
                  <div className="flex-1" key={index}>
                    {image && (
                      <GatsbyImage alt={column.image.alt || `Photo ${index}`} image={image} />
                    )}
                    {column.content?.html && (
                      <div
                        className="py-4 lg:pb-0 text-2xl"
                        dangerouslySetInnerHTML={{
                          __html: highlightString(column.content.html, column.highlightColor || ''),
                        }}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          )}
          <hr className="my-10 bg-light-gray border-1 border-light-gray" />
          <PrismicRichText fallback={<></>} field={slice.primary.content?.richText} />
          {slice.primary.ctaLink?.text && (
            <div className="font-primary">
              <ButtonLink
                backgroundColor={slice.primary.ctaBackgroundColor || undefined}
                className="mt-4"
                textColor={slice.primary.ctaTextColor || undefined}
                title={slice.primary.ctaText?.text}
                to={slice.primary.ctaLink.text}
              >
                {slice.primary.ctaText?.text}
              </ButtonLink>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
