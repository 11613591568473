import * as React from 'react'

import { SliceComponentProps } from '@prismicio/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { highlightString } from '../../util'

import { HeroBannerSlice } from './types'

export const HeroBanner = ({ slice }: SliceComponentProps<HeroBannerSlice>) => {
  const logo = getImage(slice.primary.logo)

  return (
    <div
      className="h-[500px] lg:h-[600px] bg-center bg-no-repeat bg-cover flex flex-col items-center justify-center mx-auto"
      style={{
        backgroundColor: slice.primary.backgroundColor || '#ffffff',
        backgroundImage: `url(${slice.primary.backgroundImage.url || 'none'})`,
      }}
    >
      {slice.primary.content?.html && (
        <div className="w-full lg:max-w-7xl mx-auto px-4 flex justify-center lg:justify-end">
          <div className="flex flex-col items-center justify-center">
            {logo && (
              <GatsbyImage
                alt={slice.primary.logo.alt || 'Banner Logo'}
                image={logo}
                loading="eager"
              />
            )}
            <section
              className="text-center flex flex-col items-center [&>h1]:text-3xl [&>h1]:lg:text-6xl [&>h2]:text-2xl [&>h2]:lg:text-3xl"
              dangerouslySetInnerHTML={{
                __html: highlightString(
                  slice.primary.content.html,
                  slice.primary.highlightColor || ''
                ),
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}
