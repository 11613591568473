import * as React from 'react'

import { SliceZone } from '@prismicio/react'
import { graphql, HeadFC, HeadProps, PageProps } from 'gatsby'

import { BrandProfile } from '../components/brand-profile'
import { ColumnedContent } from '../components/columned-content'
import { HeroBanner } from '../components/hero-banner'
import { ImageAndText } from '../components/image-and-text'
import { ProductShowcase } from '../components/product-showcase'
import { TextWithButton } from '../components/text-with-button'
import { VideoShowcase } from '../components/video-showcase'

const components = {
  brand_profile: BrandProfile,
  columned_content: ColumnedContent,
  hero_banner: HeroBanner,
  image_and_text: ImageAndText,
  product_showcase: ProductShowcase,
  text_with_button: TextWithButton,
  video_showcase: VideoShowcase,
}

interface DataProps {
  prismicSwHomePage: Queries.PrismicSWHomePageQuery['prismicSwHomePage'] | null
}

const IndexPage = ({ data: { prismicSwHomePage } }: PageProps<DataProps>) => (
  <SliceZone
    components={components}
    defaultComponent={() => <></>}
    slices={prismicSwHomePage?.data.body}
  />
)

export const query = graphql`
  query PrismicSWHomePage {
    prismicSwHomePage {
      data {
        body {
          ... on PrismicSwHomePageDataBodyHeroBanner {
            id
            primary {
              backgroundColor: background_color
              backgroundImage: background_image {
                alt
                url
              }
              content {
                html
              }
              highlightColor: highlight_color
              logo {
                alt
                gatsbyImageData(layout: FIXED, width: 350)
              }
            }
            slice_type
          }
          ... on PrismicSwHomePageDataBodyBrandProfile {
            id
            items {
              attribute {
                text
              }
            }
            primary {
              backgroundColor: background_color
              title {
                text
              }
            }
            slice_type
          }
          ... on PrismicSwHomePageDataBodyVideoShowcase {
            id
            primary {
              highlightColor: highlight_color
              subtitle {
                richText
              }
              title {
                text
              }
              video {
                embedUrl: embed_url
              }
            }
            slice_type
          }
          ... on PrismicSwHomePageDataBodyTextWithButton {
            id
            primary {
              backgroundColor: background_color
              buttonBackgroundColor: button_background_color
              buttonLink: button_link {
                text
              }
              buttonText: button_text {
                text
              }
              buttonTextColor: button_text_color
              openLinkInNewTab: open_link_in_new_tab
              subtitle {
                richText
              }
              title {
                text
              }
            }
            slice_type
          }
          ... on PrismicSwHomePageDataBodyImageAndText {
            id
            primary {
              backgroundColor: background_color
              backgroundImage: background_image {
                url
              }
              content {
                richText
              }
              ctaBackgroundColor: cta_background_color
              ctaLink: cta_link {
                text
              }
              ctaText: cta_text {
                text
              }
              ctaTextColor: cta_text_color
              headline {
                text
              }
              highlightColor: highlight_color
              image {
                alt
                gatsbyImageData
              }
              textOnLeft: text_on_left
            }
            slice_type
          }
          ... on PrismicSwHomePageDataBodyColumnedContent {
            id
            items {
              content {
                html
              }
              highlightColor: highlight_color
              image {
                alt
                gatsbyImageData
              }
            }
            primary {
              backgroundColor: background_color
              backgroundImage: background_image {
                url
              }
              content {
                richText
              }
              ctaBackgroundColor: cta_background_color
              ctaLink: cta_link {
                text
              }
              ctaText: cta_text {
                text
              }
              ctaTextColor: cta_text_color
              headline {
                text
              }
              highlightColor: highlight_color
              textColor: text_color
            }
            slice_type
          }
          ... on PrismicSwHomePageDataBodyProductShowcase {
            id
            items {
              ctaLink: cta_link {
                text
              }
              ctaText: cta_text {
                text
              }
              image {
                alt
                gatsbyImageData(height: 350)
              }
              openLinkInNewTab: open_link_in_new_tab
              price {
                text
              }
              title {
                text
              }
            }
            primary {
              heading {
                text
              }
              highlightColor: highlight_color
            }
            slice_type
          }
        }
        seoDescription: seo_description {
          text
        }
        seoTitle: seo_title {
          text
        }
      }
    }
  }
`

export default IndexPage

export const Head: HeadFC<DataProps> = ({ data }: HeadProps<DataProps>) => (
  <>
    <title>{data.prismicSwHomePage?.data.seoTitle?.text || 'Scout Wild™'}</title>
    <meta name="description" content={data.prismicSwHomePage?.data.seoDescription?.text || ''} />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  </>
)
